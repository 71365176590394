import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import Image from 'components/image';
import { Row, Col } from 'components/ui/wrapper';
import { ImageBoxWrapper, SectionContainer } from './section-right-image.style';
import Img from 'gatsby-image';
import Button from 'components/ui/button';
import Heading from 'components/ui/heading';
import parse from 'html-react-parser';
import Subtitle from 'components/ui/subtitle';

export interface SectionRightImageProps {
  contents?: {
    title: string;
    subtitle?: string;
    body: string;
  };
  link?: {
    link: string;
    title: string;
  };
  image: any;
  badge?: any;
  headingStyle?: any;
  className?: string;
}

const SectionRightImage: FC<SectionRightImageProps> = ({
  headingStyle,
  contents,
  badge,
  link,
  image,
  className,
}) => (
  <SectionContainer className={className}>
    <Row className="mr-0 ml-0 align-items-center">
      <Col lg={6} className="mt-5 mb-5">
        <>
          {badge?.childImageSharp && (
            <Img
              fixed={badge.childImageSharp?.fixed}
              style={{ marginBottom: '20px' }}
            />
          )}
          {contents && contents.subtitle && (
            <Subtitle>{contents.subtitle}</Subtitle>
          )}
          {contents && contents.title && (
            <Heading {...headingStyle}>{parse(contents.title)}</Heading>
          )}

          {contents && contents.body && <ReactMarkdown source={contents.body} />}

          {link && link.link && link.title && (
            <Button to={link.link} varient="texted">
              {link.title}
            </Button>
          )}
        </>
      </Col>
      <Col lg={6} className="pr-0 mt-5 mt-lg-0 col-image-container">
        <ImageBoxWrapper>
          {image?.childImageSharp && (
            <Image
              fluid={image.childImageSharp?.fluid}
              align="right"
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </ImageBoxWrapper>
      </Col>
    </Row>
  </SectionContainer>
);

SectionRightImage.defaultProps = {
  headingStyle: {
    mb: '15px',
    align: 'left',
    as: 'h3',
  },
};

export default SectionRightImage;
