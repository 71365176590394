import React, { FC } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Heading from 'components/ui/heading';
import { TabPaneWrap } from './tab-pane-content.style';
import { StyledComponentsBaseStylingProps } from 'theme';

interface Props {
  title?: string;
  body?: string;
  items?: {
    id: string;
    title?: string;
    body?: string;
  }[];
  textStyle?: StyledComponentsBaseStylingProps;
  headingStyle?: StyledComponentsBaseStylingProps;
  itemStyle?: {
    heading: StyledComponentsBaseStylingProps;
  };
}

const TabPaneContent: FC<Props> = ({
  textStyle,
  headingStyle,
  itemStyle,
  title,
  body,
  items,
  ...rest
}) => {
  return (
    <TabPaneWrap {...rest}>
      {title && <Heading {...headingStyle}>{title}</Heading>}
      {body && <ReactMarkdown>{body}</ReactMarkdown>}
      {items &&
        items.map((item) => (
          <div key={item.id}>
            {item.title && (
              <Heading {...itemStyle?.heading}>{item.title}</Heading>
            )}
            {item.body && <ReactMarkdown>{item.body}</ReactMarkdown>}
          </div>
        ))}
    </TabPaneWrap>
  );
};

TabPaneContent.propTypes = {
  textStyle: PropTypes.object,
  headingStyle: PropTypes.object,
};

TabPaneContent.defaultProps = {
  textStyle: {
    fontSize: '18px',
    mb: '30px',
  },
  headingStyle: {
    as: 'h3',
    fontSize: '40px',
    mb: '40px',
  },
  itemStyle: {
    heading: {
      as: 'h6',
      mb: '5px',
      mt: '25px',
    },
  },
};

export default TabPaneContent;
