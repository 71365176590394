import React from 'react';
import SEO from 'components/seo';
import Hero from 'components/hero';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import TabsArea from 'containers/partnerships/tabs-area/index.tsx';
import FormArea from 'containers/partnerships/form-area';
import { useStaticQuery, graphql } from 'gatsby';
import PartnershipsCallout from 'containers/partnerships/callout';
import PartnershipsAbout from 'containers/partnerships/about-area';
import PressReleases from 'containers/partnerships/press-releases';
import FeaturedStoriesPartnerships from 'containers/partnerships/featured-stories';
import bgPartnerships from 'assets/images/bg/headers/Partnership-Hero.png';

const IndexPartnerships = ({ location }) => {
  const { partnershipsHeroJson: data } = useStaticQuery(graphql`
    query partnershipsHeroDataQuery {
      partnershipsHeroJson {
        hero {
          title
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <SEO title="Partnerships" image={bgPartnerships} />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero
          title={data?.hero?.title}
          button={data?.hero?.button}
          hero="partnerships"
        />
        <PartnershipsCallout />
        <PartnershipsAbout />
        <TabsArea />
        <FormArea />
        <PressReleases />
        <FeaturedStoriesPartnerships />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPartnerships;
