import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TabsArea from 'components/ui/tabs-area';

interface Tab {
  id: string;
  name: string;
  title: string;
  body: string;
  items: TabItem[];
}

interface TabItem {
  id: string;
  title?: string;
  body?: string;
}

const PartnershipTabs = () => {
  const data = useStaticQuery(graphql`
    query partnershipsTabsQuery {
      content: partnershipsTabsJson {
        header {
          title
          subtitle
        }
        body
        tabs: tabs {
          id
          name
          title
          body
          items {
            id
            title
            body
          }
        }
      }
    }
  `);
  if (!data.content) {
    return null;
  }

  const partnerProgram = data.content;

  return <TabsArea {...partnerProgram} />;
};

export default PartnershipTabs;
