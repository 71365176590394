import styled, { css } from 'styled-components';
import bgAboutUs from 'assets/images/bg/headers/About-Us-Header.png';
import bgVentureCapital from 'assets/images/bg/headers/CVC-Header.png';
import bgPartnerships from 'assets/images/bg/headers/Partnership-Hero.png';
import bgPortfolio from 'assets/images/bg/headers/Portfolio-Header.png';
import bgCareers from 'assets/images/bg/headers/Careers-Header.jpeg';
import bgStories from 'assets/images/bg/headers/Stories-Header.png';
import bgTrust from 'assets/images/bg/headers/TT-Hero.png';
import bgScenarios2030 from 'assets/images/bg/headers/Scenarios-2030-Header.png';
import mobileBg from 'assets/images/bg/black-gradient-banner-mobile.png';
import { device } from 'theme';

export const HeroWrapper = styled.section<{ hero?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: #ffffff;
  background-position: auto 100%;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) =>
    props.hero === 'philosophy' &&
    css`
      background-image: url(${bgAboutUs});
    `}
  ${(props) =>
    props.hero === 'portfolio' &&
    css`
      background-image: url(${bgPortfolio});
    `}
  ${(props) =>
    props.hero === 'partnerships' &&
    css`
      background-image: url(${bgPartnerships});
    `}
  ${(props) =>
    props.hero === 'venture-capital' &&
    css`
      background-image: url(${bgVentureCapital});
    `}  
  ${(props) =>
    props.hero === 'careers' &&
    css`
      background-image: url(${bgCareers});
    `}
  ${(props) =>
    props.hero === 'stories' &&
    css`
      background-image: url(${bgStories});
    `}  
  ${(props) =>
    props.hero === 'trust' &&
    css`
      background-image: url(${bgTrust});
    `}
  ${(props) =>
    props.hero === 'scenarios-of-2030' &&
    css`
      background-image: url(${bgScenarios2030});
    `}
  height: 382px;

  @media ${device.medium} {
    background-position: 30% center;
    ${(props) =>
    props.hero === 'portfolio' &&
      css`
        background-position: 40% center;
      `}
  }

  @media ${device.small} {
    background-image: url(${mobileBg});
    background-size: 100% 100%;
  }
`;

export const HeroTextBox = styled.div<{ hero?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: right top;
  background-repeat: no-repeat;

  h1 {
    font-family: ${(props) => props.theme.fontFamily.hero};
    font-size: ${(props) => props.theme.fontSizes.hero};
    line-height: ${(props) => props.theme.lineHeights.hero};
    font-weight: ${(props) => props.theme.fontWeight.hero};
    color: ${(props) => props.theme.colors.white};
    margin: 15px 0;
  }

  nav {
    font-family: ${(props) => props.theme.fontFamily.hero};
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.themeColor};
    text-transform: uppercase;
  }

  @media ${device.medium} {
    width: 100%;
    padding: 0 50px;
  }

  @media ${device.small} {
    padding: 0 25px;
  }
`;

export const HeroTextWrapper = styled.div``;
