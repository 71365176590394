import React, { BaseSyntheticEvent, FC } from 'react';
import RadioButton from './radio-button';

interface OptionItem {
  id: string;
  value: any;
  label: string;
}

interface RadioGroupProps {
  name: string;
  options: OptionItem[];
  onChange: (e: BaseSyntheticEvent) => void;
  selectedValue: any;
}

const RadioGroup: FC<RadioGroupProps> = ({ name, options, onChange, selectedValue }) => (
  <>
    {options.map(({ id, value, label }) => (
      <RadioButton
        key={`${name}-${id}`}
        id={id}
        value={value}
        label={label}
        name={name}
        onChange={onChange}
        selectedValue={selectedValue}
      />
    ))}
  </>
);

export default RadioGroup;
