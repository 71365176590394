import styled from 'styled-components';
import { device } from 'theme';

export const ImageBoxWrapper = styled.div`
  width: 100%;
`;

export const SectionContainer = styled.section`
  width: 100%;
  padding: 0 0 50px 150px;

  @media ${device.small} {
    padding: 0 0 25px 75px;
  }
`;
