import SectionTitle from 'components/ui/section-title';
import { Container } from 'components/ui/wrapper';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import SwiperSlider from 'components/ui/swiper';
import PressRelease, { PressReleaseType } from './press-release';

interface Props {
  sectionTitleStyle?: object;
  slider?: object;
  sliderStyle?: object;
  containerStyle?: object;
}

const PressReleases: FC<Props> = ({
  sectionTitleStyle,
  slider,
  sliderStyle,
  containerStyle,
}) => {
  const data = useStaticQuery(graphql`
    query partnershipsPagePressReleasesQuery {
      content: partnershipsPressReleasesJson {
        header {
          title
          subtitle
        }
        pressRelease {
          title
          body
          button {
            link
            title
          }
        }
      }
    }
  `);

  if (!data?.content) {
    return null;
  }

  const pressReleases = data.content;

  return (
    <Container {...containerStyle}>
      <SectionTitle {...pressReleases.header} {...sectionTitleStyle} />
      <SwiperSlider {...sliderStyle} settings={slider}>
        {pressReleases.pressRelease.map((pressRelease: PressReleaseType, i: number) => (
          <div key={`release-${i}`}>
            <PressRelease {...pressRelease} />
          </div>
        ))}
      </SwiperSlider>
    </Container>
  );
};

PressReleases.defaultProps = {
  sectionTitleStyle: {
    mb: '60px',
  },
  containerStyle: {
    pb: '50px',
  },
  slider: {
    loop: true,
    slidesPerView: 2,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      1110: {
        slidesPerView: 2,
      },
    },
  },
  sliderStyle: {
    pagination: {
      mt: '28px',
    },
  },
};

export default PressReleases;
