import styled, { css } from 'styled-components';

export const RadioButtonLabel = styled.label<{ isSelected: boolean }>`
  box-shadow: 7px -9px 15px 3px rgb(0 0 0 / 14%);
  border-radius: 15px;
  height: 100%;
  padding: 15px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  ${(props) =>
    props.isSelected &&
    css`
      box-shadow: none;
      background: ${(props) => props.theme.colors.primaryAccent};
      color: ${(props) => props.theme.colors.white};
    `}
`;

export const RadioButtonWrapper = styled.div`
  padding: 25px;
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }
`;

export const RadioGroupWrapper = styled.div``;
