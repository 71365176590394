import styled from 'styled-components';
import { device } from 'theme';

export const TabWrapper = styled.section`
  padding: 50px 100px 50px 50px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 50px;
  @media ${device.small} {
    padding: 50px 25px;
    grid-template-columns: 1fr;
  }
`;

export const TabControlsWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const ProgramWrapper = styled.div``;
