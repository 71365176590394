import React, { useState } from 'react';

export interface Tab {
  id: string;
  name: string;
  title?: string;
  body?: string;
  items?: TabItem[];
}

export interface TabOption {
  id: string;
  value: string;
  label: string;
}

export interface TabItem {
  id: string;
  title?: string;
  body?: string;
}

export const useTabs = ({ tabs }: { tabs: Tab[] }) => {
  const firstTab = tabs[0].id;

  const [selectedOption, setSelectedOption] = useState(firstTab);

  const handleTabSelect = (e: React.BaseSyntheticEvent) => setSelectedOption(e.target.value);

  const tabOptions: TabOption[] = tabs.map((tab: Tab) => ({
    id: tab.id,
    value: tab.id,
    label: tab.name,
  }));

  const selectedTab: Tab = tabs.filter((tab: Tab) => tab.id === selectedOption)[0];

  return {
    tabOptions,
    selectedTab,
    selectedOption,
    handleTabSelect,
  };
};
