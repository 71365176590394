import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import { Container, Row, Col } from 'components/ui/wrapper';
import Heading from 'components/ui/heading';
import Form, {
  FormGroup,
  Label,
  Input,
  Select,
  Textarea,
  Checkbox,
} from 'components/ui/form';
import Button from 'components/ui/button';
import Anchor from 'components/ui/anchor';
import { FormWrapper } from './form-area.style';

const FormArea = ({ headingStyle }) => {
  const data = useStaticQuery(graphql`
    query partnershipsFormAreaQueryData {
      content: partnershipsFormJson {
        formTitle
      }
    }
  `);

  const form = data.content;

  return (
    <FormWrapper className="top-left-full-shadow-corner">
      <Container>
        <Row>
          <Col lg={10} className="mt-5 mt-lg-0 offset-lg-1">
            {form?.formTitle && (
              <Heading {...headingStyle}>{parse(form?.formTitle)}</Heading>
            )}
            <Form
              action="https://atbventures.us4.list-manage.com/subscribe/post"
              method="POST"
            >
              <input type="hidden" name="u" value="ba96a1c6f9fa269660f4104b4" />
              <input type="hidden" name="id" value="7eac8ab38d" />
              <Row>
                <Col lg={6}>
                  <FormGroup mb="20px">
                    <Input
                      type="text"
                      name="NAME"
                      id="full_name"
                      placeholder="* Full Name"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup mb="20px">
                    <Input
                      type="email"
                      name="EMAIL"
                      id="email"
                      placeholder="* Email"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup mb="20px">
                    <Input
                      type="text"
                      name="COMPANY"
                      id="company"
                      placeholder="* Company"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup mb="20px">
                    <Input
                      type="text"
                      name="WEBSITE"
                      id="website"
                      placeholder="Website"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormGroup mb="20px">
                    <Select name="STREAM" id="stream" required>
                      <option value="">* Stream</option>
                      <option value="Co-develop">Co-Develop</option>
                      <option value="Scale">Scale</option>
                      <option value="Crowdsource">Crowdsource</option>
                      <option value="Exchange">Exchange</option>
                      <option value="Investment">Investment</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormGroup mb="20px">
                    <Textarea
                      name="MESSAGE"
                      id="message"
                      placeholder="Please tell us more about yourself and why are you interested in partnering with ATB."
                    ></Textarea>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4, order: 1 }} xs={{ span: 12, order: 3 }}>
                  <Button type="submit" hover="1">
                    Get in touch
                  </Button>
                </Col>
                <Col md={{ span: 4, order: 2 }} xs={{ span: 12, order: 1 }}>
                  <Label>
                    <Checkbox name="group[68724][1]" required />I agree to
                    the&nbsp;
                    <Anchor
                      color="primary"
                      path={'/privacy-policy'}
                      target={'_blank'}
                    >
                      privacy policy
                    </Anchor>
                  </Label>
                </Col>
                <Col md={{ span: 4, order: 3 }} xs={{ span: 12, order: 2 }}>
                  <Label>
                    <Checkbox name="group[68724][2]" />
                    Sign me up for the newsletter
                  </Label>
                </Col>
              </Row>

              {/* Anti-spam field for MailChimp */}
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_ba96a1c6f9fa269660f4104b4_7eac8ab38d"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </FormWrapper>
  );
};

FormArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

FormArea.defaultProps = {
  headingStyle: {
    as: 'h3',
    mb: '30px',
    textalign: 'center',
  },
};

export default FormArea;
