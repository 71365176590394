import styled from 'styled-components';
import { device } from 'theme';

export const FormWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 114px;
  @media ${device.small} {
    padding: 100px 25px 64px;
  }
`;
