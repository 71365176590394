import React, { FC, Fragment } from 'react';
import Anchor from 'components/ui/anchor';
import Button from 'components/ui/button';
import Caption from 'components/ui/caption';
import Image from 'components/image';
import ReactMarkdown from 'react-markdown';
import {
  BlogWrapper,
  BlogMedia,
  BlogThumb,
  BlogInfo,
  BlogHeader,
  BlogTitle,
  BlogExcerpt,
  ReadMoreBtn,
} from './blog.style';
import Subtitle from 'components/ui/subtitle';

export interface StoryContent {
  id: string;
  photo: any;
  title: string;
  slug: string;
  excerpt: string;
  postedDate: string;
}

interface Props {
  content: StoryContent;
  wrapStyle?: object;
  metaStyle?: object;
  buttonStyle?: object;
}

const Blog: FC<Props> = ({ content, ...restProps }) => {
  const { photo, title, slug, excerpt, postedDate } = content;
  const { wrapStyle, metaStyle, buttonStyle } = restProps;
  return (
    <Fragment>
      <BlogWrapper {...wrapStyle}>
        {photo && (
          <BlogMedia>
            <BlogThumb>
              <Anchor path={`/news/${slug}`}>
                <Image fluid={photo.childImageSharp.fluid} alt={title} />
              </Anchor>
            </BlogThumb>
          </BlogMedia>
        )}
        <BlogInfo>
          <BlogHeader>
            {title && (
              <BlogTitle>
                <Anchor path={`/news/${slug}`}>{title}</Anchor>
              </BlogTitle>
            )}
            {postedDate && <Subtitle {...metaStyle}>{postedDate}</Subtitle>}
          </BlogHeader>
          {excerpt && (
            <BlogExcerpt>
              <Caption>
                <ReactMarkdown source={excerpt} />
              </Caption>
            </BlogExcerpt>
          )}
          <ReadMoreBtn>
            <Button to={`/news/${slug}`} {...buttonStyle}>
              Read more
            </Button>
          </ReadMoreBtn>
        </BlogInfo>
      </BlogWrapper>
    </Fragment>
  );
};

Blog.defaultProps = {
  metaStyle: {
    m: '3px 0 7px',
    as: 'h6',
    fontSize: '14px',
  },
  buttonStyle: {
    varient: 'texted',
    fontWeight: 500,
    fontSize: '14px',
    pb: '4px',
    hover: 'false',
  },
  wrapStyle: {
    mb: '20px',
  },
};

export default Blog;
