import Heading from 'components/ui/heading';
import Text from 'components/ui/text';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { CalloutWrapper } from './callout.style';
import parse from 'html-react-parser';
import Button from 'components/ui/button';

export interface Props {
  title?: string;
  body?: string;
  button?: {
    link: string;
    title: string;
  };
  className?: string;
  titleStyle?: object;
  textStyle?: object;
  buttonStyle?: object;
}

const Callout: FC<Props> = ({
  title,
  body,
  button,
  className,
  titleStyle,
  textStyle,
  buttonStyle,
}) => {
  return (
    <CalloutWrapper className={className}>
      {title && <Heading {...titleStyle}>{parse(title) as string}</Heading>}
      {body && (
        <Text {...textStyle}>
          <ReactMarkdown>{body}</ReactMarkdown>
        </Text>
      )}
      {button && (
        <Button to={button.link} {...buttonStyle}>
          {button.title}
        </Button>
      )}
    </CalloutWrapper>
  );
};

Callout.defaultProps = {
  titleStyle: {
    textalign: 'center',
    mb: '20px',
  },
  textStyle: {
    align: 'center',
    fontWeight: 400,
  },
  buttonStyle: {
    varient: 'texted',
    width: '100%',
  },
};

export default Callout;
