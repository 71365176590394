import React from 'react';
import { HeroWrapper, HeroTextBox, HeroTextWrapper } from './hero.style';
import Button from 'components/ui/button';
import Heading from 'components/ui/heading';
import parse from 'html-react-parser';

interface HeroProps {
  title?: string;
  button?: {
    link: string;
    title: string;
  };
  hero?: string;
  headingStyle: CSSStyleSheet;
}

const Hero = ({ title, button, hero, headingStyle }: HeroProps) => {
  return (
    <HeroWrapper hero={hero}>
      <HeroTextBox hero={hero}>
        <HeroTextWrapper>
          {title && <Heading {...headingStyle}>{parse(title) as string}</Heading>}
          {button && button.title && button.link && (
            <Button to={button?.link} varient={'texted'}>
              {button.title}
            </Button>
          )}
        </HeroTextWrapper>
      </HeroTextBox>
    </HeroWrapper>
  );
};

Hero.defaultProps = {
  headingStyle: {
    as: 'h1',
    textalign: 'center',
  },
};

export default Hero;
