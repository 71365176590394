import React, { FC } from 'react';
import TabPaneContent from './tab-pane-content';
import {
  TabWrapper,
  TabControlsWrapper,
  ButtonsWrapper,
  ProgramWrapper,
} from './tabs-area.style';
import RadioGroup from 'components/ui/radio-group';
import SectionTitle from 'components/ui/section-title';
import ReactMarkdown from 'react-markdown';
import { Tab, useTabs } from 'utils/useTabs';

interface Props {
  header: {
    title?: string;
    subtitle?: string;
  };
  body?: string;
  tabs: Tab[];
  titleStyles?: object;
  tabPaneStyle?: object;
}

const TabsSection: FC<Props> = ({
  header,
  body,
  tabs,
  titleStyles,
  tabPaneStyle,
}) => {
  const { tabOptions, selectedOption, selectedTab, handleTabSelect } = useTabs({
    tabs,
  });

  return (
    <TabWrapper className="">
      <TabControlsWrapper>
        {header && (
          <SectionTitle
            title={header.title}
            subtitle={header.subtitle}
            {...titleStyles}
          />
        )}
        {body && <ReactMarkdown>{body}</ReactMarkdown>}
        <ButtonsWrapper>
          <RadioGroup
            name="select-tab"
            selectedValue={selectedOption}
            onChange={handleTabSelect}
            options={tabOptions}
          />
        </ButtonsWrapper>
      </TabControlsWrapper>
      <ProgramWrapper>
        {selectedTab && (
          <TabPaneContent
            key={`tab-${selectedTab.id}`}
            {...selectedTab}
            {...tabPaneStyle}
          />
        )}
      </ProgramWrapper>
    </TabWrapper>
  );
};

TabsSection.defaultProps = {
  titleStyles: {
    mb: '30px',
  },
  tabPaneStyle: {
    as: 'div',
    mt: '70px',
    responsive: {
      small: {
        mt: '50px',
      },
    },
  },
};

export default TabsSection;
