import styled from 'styled-components';
import { device } from 'theme';

export const BlogWrapper = styled.section`
  padding: 50px 160px 100px;
  @media ${device.medium} {
    padding: 50px 0 100px;
  }
`;

export const RecentBlogWrap = styled.div`
  margin-top: 30px;
`;

export const FeaturedBlogWrap = styled.div`
  @media ${device.small} {
    margin-bottom: 50px;
  }
`;
