import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Callout from 'components/callout';
import { FC } from 'react';
import { StyledComponentsBaseStylingProps } from 'theme';

interface Props {
  titleStyle?: StyledComponentsBaseStylingProps;
}

const PartnershipsCallout: FC<Props> = ({ titleStyle }) => {
  const data = useStaticQuery(graphql`
    query PartnershipsCallout {
      content: partnershipsCalloutJson {
        title
        body
      }
    }
  `);

  const { content } = data;

  if (!content) {
    return null;
  }

  return (
    <Callout
      className=""
      title={content.title}
      body={content.body}
      titleStyle={titleStyle}
    />
  );
};

PartnershipsCallout.defaultProps = {
  titleStyle: {
    m: '0 auto',
    textalign: 'center',
    maxwidth: '800px',
  },
};

export default PartnershipsCallout;
