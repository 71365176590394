import Button from 'components/ui/button';
import Heading from 'components/ui/heading';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import parse from 'html-react-parser';

interface Props extends PressReleaseType {
  titleStyle?: object;
}

export interface PressReleaseType {
  title: string;
  body: string;
  button: {
    title: string;
    link: string;
  };
}

const PressRelease: FC<Props> = ({ title, body, button, titleStyle }) => (
  <div>
    {title && <Heading {...titleStyle}>{parse(title) as string}</Heading>}
    {body && <ReactMarkdown>{body}</ReactMarkdown>}
    {button && (
      <Button varient={'texted'} to={button.link}>
        {button.title}
      </Button>
    )}
  </div>
);

PressRelease.defaultProps = {
  titleStyle: {
    as: 'h3',
    fontSize: '25px',
    lineHeight: '36px',
    mb: '16px',
  },
};

export default PressRelease;
