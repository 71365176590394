import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'components/ui/wrapper';
import SectionTitle from 'components/ui/section-title';
import { BlogWrapper } from './stories-preview.style';
import Button from 'components/ui/button';
import Blog, { StoryContent } from 'components/blog';
import ReactMarkdown from 'react-markdown';

interface Props {
  sectionTitleStyle?: object;
  className?: string;
  title?: string;
  subtitle?: string;
  body?: string;
  button?: {
    title: string;
    link: string;
  };
  stories: StoryContent[];
}

interface PhotoData {
  node: {
    title: string;
    photo?: any;
  }
}

const StoriesPreview: FC<Props> = ({
  sectionTitleStyle,
  title,
  subtitle,
  body,
  button,
  stories,
  className,
}) => {
  const data = useStaticQuery(graphql`
    query storiesJsonQueryData {
      photos: allStoriesJson {
        edges {
          node {
            title
            photo {
              childImageSharp {
                fluid(quality: 100, maxWidth: 850, maxHeight: 550) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  `);
  const photos = data.photos.edges;

  // match stories from JSON to content from Strapi using title as a key:
  stories = stories.map((story) => {
    const match = photos.find(
      (photoData: PhotoData ) =>
        photoData.node.title.toLowerCase() === story.title.toLowerCase(),
    );
    if (match) {
      // match icons to items in Strapi
      story.photo = match.node.photo;
    }
    return story;
  });

  return (
    <BlogWrapper className={className}>
      <Container>
        <Row className="mb-5">
          <Col lg={12} align="center">
            <>
              {(title || subtitle) && (
                <SectionTitle
                  {...sectionTitleStyle}
                  title={title}
                  subtitle={subtitle}
                />
              )}
              {body && <ReactMarkdown>{body}</ReactMarkdown>}
              {button && button.title && (
                <Button to={button.link} varient={'texted'}>
                  {button.title}
                </Button>
              )}
            </>
          </Col>
        </Row>
        <Row>
          {stories &&
            stories.map((featuredBlog: StoryContent) => (
              <Col lg={4} key={`stories-${featuredBlog.id}`}>
                <Blog content={featuredBlog} />
              </Col>
            ))}
        </Row>
      </Container>
    </BlogWrapper>
  );
};

StoriesPreview.propTypes = {
  sectionTitleStyle: PropTypes.object,
};

StoriesPreview.defaultProps = {
  sectionTitleStyle: {
    mb: '15px',
    responsive: {
      small: {
        mb: '40px',
      },
    },
  },
};

export default StoriesPreview;
