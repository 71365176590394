import { WrapperStyled } from 'components/ui/wrapper/wrapper.style';
import styled from 'styled-components';

export const TabPaneWrap = styled(WrapperStyled)``;

export const IconListBody = styled.span`
  font-size: 18px;
  line-height: 24px;
  color: white;
`;
