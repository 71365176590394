import React, { BaseSyntheticEvent, FC } from 'react';
import { RadioButtonWrapper, RadioButtonLabel } from './radio-group.style';

interface RadioButtonProps {
  label: string;
  value: any;
  id: string;
  name: string;
  selectedValue: any;
  onChange: (e: BaseSyntheticEvent) => void;
}

const RadioButton: FC<RadioButtonProps> = ({ label, value, id, name, selectedValue, onChange }) => (
  <RadioButtonWrapper>
    <RadioButtonLabel
      htmlFor={id}
      className={'radioButtonLabel'}
      isSelected={value === selectedValue}
    >
      {label}
    </RadioButtonLabel>
    <input
      type="radio"
      id={id}
      value={value}
      name={name}
      checked={value === selectedValue}
      onChange={onChange}
    />
  </RadioButtonWrapper>
);

export default RadioButton;
