import StoriesPreview from 'components/blog/stories-preview';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const FeaturedStoriesPartnerships = () => {
  const data = useStaticQuery(graphql`
    query partnershipsPageStoriesQueryData {
      content: partnershipsFeaturedStoriesJson {
        title
        subtitle
        button {
          link
          title
        }
        stories {
          id
          title
          slug
          excerpt
          postedDate
        }
      }
    }
  `);

  if (!data.content) {
    return null;
  }

  return (
    <StoriesPreview
      className={'left-red-slice md-no-bg-image'}
      {...data.content}
    />
  );
};

export default FeaturedStoriesPartnerships;
