import React from 'react';
import { CaptionStyle } from './caption.style';

interface CaptionProps {
  children: JSX.Element | JSX.Element[];
}

const Caption = ({ children }: CaptionProps) => {
  return <CaptionStyle>{children}</CaptionStyle>;
};

export default Caption;
